import React, { ChangeEvent, useRef, useState, SyntheticEvent } from 'react';
import cn from 'classnames';
import { randomString } from '@helpers/stringHelpers';
import s from './input.module.scss';

interface IInput {
	type: 'date' | 'datetime-local' | 'email' | 'number' | 'password' | 'tel' | 'text' | 'time';
	className?: string;
	initValue?: string;
	placeholder?: string;
	name?: string;
	today?: boolean;
	value?: string | number;
	required?: boolean;
	regexp?: RegExp;
	onChange?(value: any): void;
	onBlur?(e?: SyntheticEvent): void;
	onFocus?(e?: SyntheticEvent): void;
	disabled?: boolean;
}

const Input: React.FC<IInput> = ({
	initValue,
	className,
	value,
	onChange,
	regexp,
	required = false,
	today,
	...props
}) => {
	const id = useRef<string>(randomString(6));
	const [isError, setIsError] = useState(false);

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		onChange(e.target.value);
		setIsError(regexp && !regexp.test(e.target.value));
	};

	return (
		<label className={cn([s.label, className])} htmlFor={id.current}>
			{required && (value === '' || value === undefined || value === null) && <span className={s.required} />}
			<input
				className={cn([s.input, { [s.error]: isError }])}
				id={id.current}
				defaultValue={initValue}
				required={required}
				min={today && new Date().toISOString().split('T')[0]}
				value={value}
				onChange={onChange ? handleChange : undefined}
				{...props}
			/>
		</label>
	);
};

export default Input;
