import React from 'react';
import Skeleton from 'react-loading-skeleton';
import cn from 'classnames';
import s from './style.module.scss';

interface ISkeletonProps {
  count?: number;
  mode?: 'historyItem' | 'row';
}

const AppSkeleton: React.FC<ISkeletonProps> = ({ count, mode }) => {
  return (
    <Skeleton count={count} className={cn([
        mode === 'historyItem' && s.historyItem,
        mode === 'row' && s.row,
      ])} />
  );
};

export default AppSkeleton;