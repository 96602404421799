import React, { PropsWithChildren } from 'react';
import s from '../modals.module.scss';
import { Modal } from '@components/Modal';
import { useTranslation } from 'react-i18next';
import { Button } from '@root/components';

interface IModalProps {
    open: boolean;

    onClose(boolean?): void;

    onConfirm(boolean?): void;
}

const TotalWeightConfirmModal: React.FC<PropsWithChildren<IModalProps>> = ({ open, onClose, onConfirm, children }) => {
    const { t } = useTranslation();

    return (
        <Modal
            open={open}
            onClose={onClose}
            onConfirm={onConfirm}
            loading={false}
            confirmText={"Подтвердить"}>
            <div className={s.modalHeader}>
                <span className={s.modalTitle}>Изменить общий вес вручную</span>
            </div>
            <div className={s.modalBody}>
                <span>Вы действительно хотите ввести общий вес вручную, а не расчитать его автоматически? При этом вес каждого габарита будет равен нулю</span>
            </div>
        </Modal>
    );
};

export default TotalWeightConfirmModal;
